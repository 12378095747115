<template>    
    <section class="section pt-0 exchange_list">
        <div class="container">
            <div class="text-center">
                <h2>Canje de premios</h2>
                <p class="text-featured pb-xl">
                    Descubre todos los premios que tenemos para ti <br>
                y la cantidad de puntos que necesitas
                </p>
            </div>            
            <div class="grid grid--three-columns grid--gap-lg mt-lg">
                <!-- <a href="#" class="home-prize" v-for="(v, k) in data" :key="k" @click="modalExchange(v)"> -->
                <div class="home-prize" v-for="(v, k) in data" :key="k">
                    <div class="home-prize__picture">
                        <img :src="baseURLStatic + v.image" alt="Club de Sastreria Barrington">
                        
                    </div>
                    <span class="home-prize__points">{{ v.point_exchange }} puntos</span>
                    <div class="home-prize__text">
                        <p>Exclusivo membresias: {{v.level.name }}.</p>
                        <h3>{{ v.name }}</h3>
                            <template v-if="v.can_exchange == true">                                
                                <button class="cta" @click="modalExchange(v)">Canjear</button>
                            </template>
                            <template v-else>
                                <button class="cta" @click="modalCanNotExchange()">Canjear</button>
                            </template>
                            
                        <!-- </template>
                        <template v-esle>
                            <button class="cta" disabled>Canjear</button>
                        </template> -->
                        
                    </div>
                    
                </div>
                <!-- </a> -->

                <!-- <a href="#" class="home-prize">
                    <div class="home-prize__picture">
                        <img src="/images/tshirt.jpg" alt="">
                    </div>
                    <span class="home-prize__points">xxxx puntos</span>
                    <div class="home-prize__text">
                        <p>Exclusivo membresias: Estandar, Bronce.</p>
                        <h3>Descripción de razón social y alcance de beneficio</h3>
                    </div>
                </a>
                <a href="#" class="home-prize">
                    <div class="home-prize__picture">
                        <img src="/images/cellphone.jpg" alt="">
                    </div>
                    <span class="home-prize__points">xxx xxx xxx puntos</span>
                    <div class="home-prize__text">
                        <p>Exclusivo membresias: Estandar, Bronce.</p>
                        <h3>Descripción de razón social y alcance de beneficio</h3>
                    </div>
                </a> -->
                
            </div>
            <br>
            <p class="text-center pt-xl" v-if="showLinkAssetExchange">                
                <a href="#" class="link-read-more" v-on:click.prevent="$emit('demo', store.state.dataToken.id)">Cargar más canjes</a>
            </p>

            
        </div>
    </section>
</template>

<script>
import { ref } from "vue";
import Swal from 'sweetalert2'
import store from "@/store";
import router from "@/router"
import API from "@/api";

export default {
    props: {
        data: Array,
        showLinkAssetExchange: Number,
    },
    name: 'UserExchange',
    setup () {
        const otherConf = ref({})
        const baseURL = API.defaults.baseURL
        const baseURLStatic = API.defaults.baseURLStatic

        var span = document.createElement("span");
        var d = document.getElementById("demo")

        span.innerHTML = '<a href="#" class="home-prize"><div class="home-prize__picture"><img src="/images/tshirt.jpg" alt="Club de Sastreria Barrington"></div><span class="home-prize__points">xxxx puntos</span><div class="home-prize__text"><p>Exclusivo membresias: Estandar, Bronce.</p><h3>Descripción de razón social y alcance de beneficio</h3></div></a>'
        

        const modalExchange = (v) => {
            var template = 
            console.log('CLICK', baseURLStatic + v.image)
                Swal.fire({
                title: `<strong>${v.name}</strong>`,                
                html:
                    
                    '<span class="title-condition">CONDICIONES</span> <br>' + 
                    `<div class="block_modal"><div><ul class="list-asset-exchange"><li>Nivel: <span>${v.level.name}</span></li><li>Puntos: <span>${v.point_exchange} puntos</span></li>` + 
                    `<li>Stock: <span>${v.stock} disponibles</span></li></ul></div>` +  
                    `<div><p class="description">${v.description}</p></div></div>` +
                    "<p class='tyc'>" + otherConf.value.disclaimer_exchange + "</p>" +
                    '<b>¿Confirma su solicitud de canje?</b><br>',                                        
                //html: span,
                imageUrl: baseURLStatic + v.image,                       
                //showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText:
                    '<i class="fa fa-thumbs-up"></i> Canjear',                
                cancelButtonText:
                    '<i class="fa fa-thumbs-down">Cancelar</i>',                
                //text: '<b>¿Confirma su solicitud de canje?</b>'
                })
                .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //console.log(v.id)
                        submitAssetExchangeActivity(v)
                        //Swal.fire('Saved!', '', 'success')
                    } else if (result.isDenied) {
                        Swal.fire('Changes are not saved', '', 'info')
                    }
                })


        }

        const submitAssetExchangeActivity = (v) => {
            let data = {
                'asset_exchange': v.id,
                'user': store.state.dataToken.id,                
            }
            API.post("/api/asset-exchange-activity", data)
                .then(response => {
                    console.log('create asset exchange activity!')
                    Swal.fire('¡Canjeado!', 'Tu solicitud de canje se envió con exito. Una confirmación te será enviada vía email con los detalles completos sobre tu proceso de canje', 'success')
                        .then((result) => {
                            if (result.isConfirmed) {
                                router.push({ name: 'UserMyPoint' })
                            }
                            
                        })                                            
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'info',
                        title: error.response.data.errors.non_field_errors,
                        text: 'Canjes',
                
                    })
                    console.log(error.response.data.errors.non_field_errors)
                })
        }

        const getOtherConf = () => {
            API.get("/api/other-conf")
                .then(response => {
                    otherConf.value = response.data                    
                })                
        }
        
        const modalCanNotExchange = () => {
            Swal.fire({
                icon: 'info',
                title: "Aún no cumples las condiciones que requiere este canje. Sigue acumulando puntos por favor.",
                text: 'Canjes',
        
            })
        }

        return { baseURL, baseURLStatic, modalExchange, getOtherConf, otherConf, store, modalCanNotExchange }
    },
    mounted() {
        this.getOtherConf();
    }
}
</script>