<template>
    <main>              
        <UserHeader />
        <section class="section pt-0 my-status">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Mi estado</span></p>
                <h2><a href="#"><img src="/images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Mi Estado</h2>
                <div class="py-lg pt-0 block-message-user-status">
                    <p class="form__success">                        
                        IMPORTANTE. Con cada compra de productos Barrington, exija a su tienda solicite la aprobación de sus puntos.
                        Tan pronto la tienda registre su compra, los puntos apareceran en su historial como PENDIENTES de aprobación 
                        por BARRINGTON.
                    </p>                    
                    
                </div>
                <!-- jkjjkjkjjkjkjjkjk -->
                <div class="block-flex">
                    <div class="pl-p-10">
                        <p class="pt-lg">
                            <router-link :to="{ name: 'UserMyPoint' }">
                                <button class="cta cta--lighten cta-status"><strong>>> Ver mis puntos</strong></button>
                            </router-link>
                        </p>
                        <p class="pt-md" v-if="user.point_next_level">Has acumulado <span>{{ user.total_point_for_level }}</span> puntos en Club Barrington.
                        <br>Aún te faltan <span>{{ user.point_next_level[0] }} </span> puntos para alcanzar la membresía {{ user.point_next_level[1] }}</p>
                        <ul class="list-status">
                            <li v-if="user.level"><span>Nivel:</span>{{ user.level.name }}</li>
                            <li><span>Mts. comprados:</span> {{ user.total_meter }}</li>
                            <li><span>Pts. activos para canjes:</span> {{ user.total_point }}</li>
                            <li><span>Pts. canjeados:</span> {{ user.redeemed_point }}</li>
                            
                        </ul>    
                        <!-- <p class="pt-lg" v-if="user.point_next_level">Has acumulado <span>{{ user.total_point_for_level }}</span> puntos en Club Barrington.
                        <br>Aún te faltan <span>{{ user.point_next_level[0] }} </span> puntos para alcanzar la membresía {{ user.point_next_level[1] }}</p> -->
                    </div>                                    
                    <div class="image_card_user">
                        <img :src="`${baseURLStatic}${dataCard[0].image}`" alt="Club de Sastreria Barrington">
                        <div class="name_user">{{ user.name_for_card }}</div>
                        <div class="nro_card">{{ user.code_user_for_card }}</div>
                    </div>
                </div>
                
                <table class="list_level_exchange"><!--se quita esta clase, "table__content" hace desparecer el menu burger -->
                    <tbody>                            
                        <tr>
                            &nbsp;
                            <td colspan="5" class="line-level-h">                                
                                <img :src="`${baseURLStatic}${lineMark}`" alt="Club de Sastreria Barrington" height="48">
                            </td>
                        </tr>
                        <tr>
                            <th>&nbsp;<br>&nbsp;</th>
                            <th v-for="(v, k) in arrayCard" :key="k">
                                <img :src="`${baseURLStatic}${v.image_mark}`" alt="Club de Sastreria Barrington" height="48">
                                <br>
                                <strong :style="`color: ${v.color};`">{{ v.name.toUpperCase() }}</strong>                                
                                <template v-if="key == 0">
                                    <br><span>0 - {{ arrayCard[k + 1].from_point - 1}} pts.</span>                                    
                                </template>
                                <template v-else-if="k <= 3">
                                    <br><span>{{ v.from_point }} - {{ arrayCard[k + 1].from_point - 1 }} pts.</span>                                     
                                </template>
                                <template v-else>
                                    <br><span>{{ v.from_point }} pts. a +</span>                                     
                                </template>                                 
                            </th>
                            
                        </tr>
                        
                    </tbody>
                </table>

                <img :src="`${baseURLStatic}${lineMarkVertical}`" alt="Club de Sastreria Barrington" height="48" class="line_v" style="display:none">
                <ul class="list_level_v" style="display:none">
                    <li v-for="(v, k) in arrayCard" :key="k">
                        <div>
                            <img :src="v.path_icon" alt="Club de Sastreria Barrington" height="48">                        
                            <strong :style="`color: ${v.color};`">{{ v.name.toUpperCase() }}</strong>
                            <template v-if="key == 0">
                                    <span>0 - {{ arrayCard[k + 1].from_point - 1}} pts.</span>                                    
                                </template>
                                <template v-else-if="k <= 3">
                                    <span>{{ v.from_point }} - {{ arrayCard[k + 1].from_point - 1 }} pts.</span>                                     
                                </template>
                                <template v-else>
                                    <span>{{ v.from_point }} pts. a +</span>                                     
                                </template>                                 
                        </div>
                        
                    </li>
                </ul>
                
                
                

                
                <UserExchange :data="dataAssetExchange" :showLinkAssetExchange="showLinkAssetExchange" v-on:demo="getAssetExchange" />
                
                
            </div>
        </section>
        
    </main>       

</template>

<script>
import { ref, watch, computed, reactive } from "vue";
import API from "@/api";
import store from '@/store'
import LineLevel from "@/components/LineLevel";
import UserExchange from "@/views/private/user/components/UserExchange";
import UserHeader from "@/views/private/user/components/UserHeader"
import { mapState, mapActions } from "vuex";

export default {
    name: 'UserStatus',
    components: {
        UserExchange,
        UserHeader,
    },
    setup() {
        const baseURLStatic = API.defaults.baseURLStatic
        var flag = false
        var page = 1
        
        if (!flag){
            var limit = 3
            var limitFactor = 3            
            var incre = 1
        }
        const showLinkAssetExchange = ref(true)
        const dataAssetExchange = ref([])
        /* const user = ref(
            {
                level:{}, 
                point_next_level:[]
            }
        ) */
        
        const arrayCard = ref([])
        const level = ref({})
        const dataCard = ref([{}])
        const lineMark = ref("")
        const lineMarkVertical = ref("")
        /* arrayCard.value = [
            {   
                level: "blue",
                path: "/images/card/blue_h.png",
                path_icon: "/images/cb_icon_nivel_blue.svg",
                color: "#003f72",
                path_line_mark_h: "/images/horizontal_1.svg",
                path_line_mark_v: "/images/vertical_1.svg",
                start_point: 0,
                end_point: 250
            },
            {
                level: "bronce",
                path: "/images/card/bronce_h.png",
                path_icon: "/images/cb_icon_nivel_bronce.svg",
                path_line_mark_h: "/images/horizontal_2.svg",
                path_line_mark_v: "/images/vertical_2.svg",
                color: "#ffa940",
                start_point: 251,
                end_point: 600
            },
            {
                level: "plata",
                path: "/images/card/plata_h.png",
                path_icon: "/images/cb_icon_nivel_plata.svg",
                path_line_mark_h: "/images/horizontal_3.svg",
                path_line_mark_v: "/images/vertical_3.svg",
                color: "#8c8c8c",
                start_point: 601,
                end_point: 1200
            },
            {
                level: "oro",
                path: "/images/card/oro_h.png",
                path_icon: "/images/cb_icon_nivel_oro.svg",
                path_line_mark_h: "/images/horizontal_4.svg",
                path_line_mark_v: "/images/vertical_4.svg",
                color: "#b3965b",
                start_point: 1201,
                end_point: 1800
            },
            {   level: "diamante",
                path: "/images/card/diamante_h.png",
                path_icon: "/images/cb_icon_nivel_diamante.svg",
                path_line_mark_h: "/images/horizontal_5.svg",
                path_line_mark_v: "/images/vertical_5.svg",
                color: "#301919",
                start_point: 1801,
                end_point: "+"
            },
        ]
 */
        const dataCardx = ref([])
        const getCard = (level, typeScreen='h') => {
            //debugger
            API.get("/api/level")
                .then(response => {
                    arrayCard.value = response.data
                    dataCard.value = arrayCard.value.filter(m => m.name.toLowerCase() == level) 
                    //debugger
                    if (typeScreen == 'h' && dataCard.value.length) {

                        lineMark.value = dataCard.value.[0].path_line_mark_h
                        lineMarkVertical.value = dataCard.value.[0].path_line_mark_v
                        /* console.log(dataCard.value.[0].path_line_mark_h) */
                    }
                })
            
            
        }

        const getAssetExchange = (user_id) => {
            /* API.get("/api/asset-exchange?can_user_exchange=" + user_id)
                .then(response => {
                    dataAssetExchange.value = response.data
                    //getCard(response.data.level.name.toLowerCase())
                }) */
            
            API.get("/api/asset-exchange?can_user_exchange=" + user_id + "&limit=" + limit + "&page=" + page)
                .then(response => {
                    incre = incre + 1
                    limit = limitFactor * incre
                    flag = true
                    /* console.log('incre=>', incre, 'limit=>', limit, "flag=>", flag) */

                    if (response.data.count <= response.data.results.length) {
                        showLinkAssetExchange.value = false                                                                    
                    }

                    dataAssetExchange.value = response.data.results     
                    console.log(showLinkAssetExchange)    
                })                
        }

        
        
        watch(() => {            
            if (store.state.user.level.name){                
                getCard(store.state.user.level.name.toLowerCase())                        
            }
        })

        return { 
        
            baseURLStatic,
            showLinkAssetExchange,
            lineMark,
            lineMarkVertical,
            dataAssetExchange, 
            getAssetExchange, 
            store, 
            //user, 
            //getUser,
            getCard,
            arrayCard,
            dataCard,
            //...mapActions(['getUser']),            
        }
    },
    computed: {
        ...mapState(['user']),
    },
    

    async created() {
        //await this.getUser(store.state.dataToken.id);
        this.getAssetExchange(store.state.dataToken.id);
        
        //console.log(this.dataUser.level.name.toLowerCase())
        //console.log(user.level.name.toLowerCase())
        //debugger
        //await this.getCard(this.dataUser.level.name.toLowerCase())
        
        
        //await this.getCard(store.state.dataToken.level_label.toLowerCase())
        //console.log(store.state.dataToken.level_label.toLowerCase(), 'CARD')
        //console.log(store.state.user, '<<<<')
        //console.log(this.a())
    }
    
    
}
</script>


